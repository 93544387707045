<template>
  <div id="admin-dashboard">
    <modal
      id="delete-project"
      v-if="showDeleteConfirm"
      @close="showDeleteConfirm = false"
    >
      <template v-slot:header>Delete project?</template>
      <template v-slot:body
        >Are you sure you want to delete project
        <strong>{{ project.name }}</strong
        >?</template
      >
      <template v-slot:footer
        ><button @mousedown="deleteProject(projectKey, project)">Delete</button
        ><button class="white" @mousedown="showDeleteConfirm = false">
          Cancel
        </button></template
      >
    </modal>
    <div class="admin-header-menu">
      <h1>Projects</h1>
      <div class="buttons">
        <button v-if="isAdmin()" @mousedown.prevent.stop="newProject">New project</button>
      </div>
    </div>
    <div id="projects" class="area-selector-wrapper" v-bind:key="hover">
      <a
        :class="'project link-unstyled '"
        @mousedown.prevent.stop="select(project)"
        href="#"
        v-for="(project, index) in projects"
        v-bind:key="index"
      >
        <edit-buttons
          @edit="select(project)"
          @duplicate="duplicate(project)"
          :trash="isAdmin()"
          :clone="isAdmin()"
          @delete="showDeleteConfirmModal(project, index)"
        />
        <h3 :id="project._id">
          {{ project.name }}
        </h3>
      </a>
    </div>
  </div>
</template>

<script>
import { hovered, hoverItem } from "../../helpers";
import EditButtons from "../../components/EditButtons";
import Modal from "../../components/Modal";
import { currentUser } from "../../store";

export default {
  components: { Modal, EditButtons },
  data: function () {
    return {
      projects: [],
      hover: 0,
      showDeleteConfirm: false,
    };
  },
  created: function () {
    const query = (currentUser().customData.admin) ? {} : {
      _id: currentUser().customData.editorForProjects
    }

    this.$store
      .dispatch("loadProjects", query)
      .then((projects) => {
        console.log(projects)
        this.projects = projects;
      })
      .catch((e) => {
        if (e.message.match(/refresh token expired/)) {
          for (const k in window.localStorage) {
            if (k.indexOf("realm-web:app") === 0) {
              delete window.localStorage[k];
            }
          }

          window.location.reload();
        }
      });
  },
  methods: {
    isAdmin() { return currentUser().customData.admin },
    hovered(id) {
      return hovered(id);
    },
    hoverItem(id) {
      hoverItem(id);
      this.hover += 1;
      this.integratedHover += 1;
    },
    newProject() {
      const project = {
        name: "Untitled project",
        indicators: [],
        nbsTypes: [],
      };
      this.$store.dispatch("createProject", project).then((p) => {
        this.$store.commit("setCurrentProject", p);
        this.$router.push({
          path: "/Admin/ProjectDashboard",
        });
      });
    },
    select(project) {
      this.$store.commit("setCurrentProject", project);

      this.$router.push({
        path: "/Admin/ProjectDashboard",
      });
    },
    duplicate(project) {
      const newProject = JSON.parse(JSON.stringify(project));
      delete newProject._id;
      delete newProject.slug;
      delete newProject.site;
      newProject.name = newProject.name + "(clone)";

      this.$store.dispatch("createProject", newProject).then((p) => {
        this.$store.commit("setCurrentProject", p);
        this.$router.push({
          path: "/Admin/ProjectDashboard",
        });
      });
    },
    showDeleteConfirmModal(project, key) {
      this.projectKey = key;
      this.project = project;
      this.showDeleteConfirm = true;
    },
    deleteProject(projectKey, project) {
      this.$store
        .dispatch("deleteProject", project)
        .then(() => {
          this.projects.splice(projectKey, 1);
          this.showDeleteConfirm = false;
        })
        .catch((e) => {
          this.error = "Cannot delete project: " + e;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/admin.scss";

#admin-dashboard {
  #projects {
    grid: 220px / 220px 220px 220px 220px;
    column-gap: auto;
  }
  .project {
    @include area-selector;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    text-align: center;
    width: 220px;
    height: 220px;

    h3 {
      margin: 0 20px;
    }
    .actions {
      position: absolute;
      top: 24px;
      left: 50px;
      margin: 0 auto;
      padding: 24px auto 48px auto;
    }
  }
}
</style>
